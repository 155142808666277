import React, { useEffect, useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import JSZip from "jszip";
import "./ZipUploader.css";
import { FileUploader } from "react-drag-drop-files";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import styled from "@emotion/styled";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import NetworkDisplay from "./NetworkDisplay";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const BigButton = styled(Button)(() => ({
  width: "300px",
  height: "60px",
  borderRadius: "50px",
  backgroundColor: "#A0AD39",
  "&:hover": {
    backgroundColor: "#c1cd66",
  },
  fontSize: "17px",
  color: "white",
}));
const not = (a, b) => a.filter((value) => b.indexOf(value) === -1);

const intersection = (a, b) => a.filter((value) => b.indexOf(value) !== -1);

const union = (a, b) => [...a, ...not(b, a)];

function ZipUploader() {
  const [zipNameA, setZipNameA] = useState([]);
  const [zipNameB, setZipNameB] = useState([]);
  const [zipContentsA, setZipContentsA] = useState([]);
  const [zipContentsB, setZipContentsB] = useState([]);
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [originalJsonOld, setOriginalJsonOld] = useState([]);
  const [originalJsonNew, setOriginalJsonNew] = useState([]);
  const [originalNetworkOld, setOriginalNetworkOld] = useState([]);
  const [originalNetworkNew, setOriginalNetworkNew] = useState([]);

  const [value, setValue] = useState("old");

  const handleChangeRadio = (event) => {
    setValue(event.target.value);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(intersection(checked, left)));
    setLeft(not(left, checked));
    setChecked(not(checked, intersection(checked, left)));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(intersection(checked, right)));
    setRight(not(right, checked));
    setChecked(not(checked, intersection(checked, right)));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const handleAllRight = (type) => {
    setRight(right.concat(left.filter((item) => item.type === type)));
    setLeft([]);
  };

  // useEffect(() => {
  //   // Ensure both left and right have been set
  //   console.log("here")
  //   if (left.length > 0 && right.length > 0) {
  //     // Create copies to manipulate
  //     const leftCopy = [...left];
  //     const rightCopy = [...right];

  //     // Mark unique flows
  //     markUniqueFlows(leftCopy, rightCopy);

  //     // Update state with the flows marked for uniqueness
  //     setLeft(leftCopy);
  //     setRight(rightCopy);
  //   }
  // }, [left, right]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };
  // Right before the customList function or inside your component's render logic
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const customList = (title, items, type) => (
      <Card
        sx={{
          height: "80dvh",
          overflow: "auto",
          width: "35dvw",
          marginTop: "30px",
        }}
      >
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{
                "aria-label": "all items selected",
              }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${
            items.filter((item) => item.type === type).length
          } selected`}
        />
        <Divider />
        <List
          sx={{
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          dense
          component="div"
          role="list"
        >
          {items
            .filter((item) => item.type === type)
            .map((item) => {
              const labelId = `transfer-list-item-${item.id}-label`;

              return (
                <ListItemButton
                  key={item.id}
                  role="listitem"
                  sx={{
                    width: "100%",
                    marginBottom: "4px",
                    backgroundColor:
                      item.origConfig === "old" ? "#FDFD96" : "#d2e7d6",
                    "&:hover": {
                      backgroundColor:
                        item.origConfig === "old" ? "#fffdaf" : "#b8d8be",
                    },
                    border:
                      item.isUnique === true ? "3px dashed #A0AD39" : "none",
                  }}
                  onClick={handleToggle(item)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(item) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={`${item.label}`}
                    secondary={item.type === "subflow" ? "Subflow" : "Flow"}
                  />
                </ListItemButton>
              );
            })}
          <ListItemButton />
        </List>
      </Card>
  );

  const resetAppState = () => {
    setZipNameA([]);
    setZipNameB([]);
    setZipContentsA([]);
    setZipContentsB([]);
    setChecked([]);
    setLeft([]);
    setRight([]);
    setOriginalJsonOld([]);
    setOriginalJsonNew([]);
    setOriginalNetworkOld([]);
    setOriginalNetworkNew([]);
    setValue("old");
  };

  const createNewFile = () => {
    const newZip = new JSZip();

    const priorityFiles = ["teldio-apps.json", "teldio-apps.zip", "VERSION.txt"];
    const priorityDirs = ["autoload.d/"];

    // Add files from zip A, except the original flows.json
    zipContentsA.forEach(([name, file]) => {
      if (!name.includes("nodered/flows.json")) {
        newZip.file(name, file.async("blob"));
      }
    });

    zipContentsB.forEach(([name, file]) => {
      if (
        priorityDirs.some((dir) => name.startsWith(dir)) ||
        priorityFiles.includes(name)
      ) {
        newZip.file(name, file.async("blob"));
      }
    });

    // Then, add remaining files from file A, excluding priority files and directories
    zipContentsA.forEach(([name, file]) => {
      if (
        !priorityDirs.some((dir) => name.startsWith(dir)) &&
        !priorityFiles.includes(name)
      ) {
        newZip.file(name, file.async("blob"));
      }
    });

    // Prepare to accumulate selected flows and related items
    const selectedFlows = [];
    const selectedSubflows = [];
    const relatedNodes = [];

    // Iterate over the right array to process selected flows from the new configuration
    right.forEach((selectedFlow) => {
      // Determine the configuration JSON to use based on the origConfig field
      const originalJson =
        selectedFlow.origConfig === "old" ? originalJsonOld : originalJsonNew;

      // Find the flow in the selected configuration JSON
      const flow = originalJson.find((f) => f.id === selectedFlow.id);

      if (flow) {
        if (flow.type === "tab") {
          selectedFlows.push(flow);
        } else if (flow.type === "subflow") {
          selectedSubflows.push(flow);
        }

        // Include nodes and subflow instances related to the selected flow
        originalJson
          .filter(
            (node) =>
              node.z === selectedFlow.id || node.type === "websocket-listener"
          )
          .forEach((node) => {
            relatedNodes.push(node);

            // If the node is a subflow instance, ensure its definition is included
            if (node.type.startsWith("subflow:")) {
              const subflowId = node.type.split(":")[1];
              const subflowDef = originalJson.find(
                (subflow) =>
                  subflow.id === subflowId && subflow.type === "subflow"
              );
              if (subflowDef && !selectedSubflows.includes(subflowDef)) {
                selectedSubflows.push(subflowDef);
              }
            }
          });
      }
    });

    // Combine the selected flows, subflows, and related nodes
    const completeFlows = [
      ...selectedFlows,
      ...selectedSubflows,
      ...relatedNodes,
    ];

    // Serialize to JSON string
    const newFlowsJson = JSON.stringify(completeFlows, null, 2);
    console.log(newFlowsJson);
    newZip.file("nodered/flows.json", newFlowsJson);

    if (value === "new") {
      console.log("new", originalNetworkNew);
      newZip.file("network.json", JSON.stringify(originalNetworkNew));
    }
    // Generate the ZIP file and trigger download
    newZip.generateAsync({ type: "blob" }).then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "mergedConfig.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      resetAppState();
    });
  };

  const handleChangeA = (file) => {
    console.log(file);
    setZipNameA(file.name);
    handleUpload(file, "A");
  };

  const handleChangeB = (file) => {
    console.log(file);
    setZipNameB(file.name);
    handleUpload(file, "B");
  };

  const parseNodeRedFlows = (json, origConfig) => {
    const flows = json
      .filter((item) => item.type === "tab" || item.type === "subflow")
      .map((flow) => ({
        id: flow.type === "tab" ? flow.id : flow.id,
        label: flow.type === "tab" ? flow.label : flow.name,
        origConfig: origConfig,
        type: flow.type,
        isUnique: false,
      }));
    return flows;
  };

  const markUniqueFlows = (flowsA, flowsB) => {
    // Mark flows in A as unique if not found in B
    flowsA.forEach((flowA) => {
      const isUnique = !flowsB.some((flowB) => flowB.id === flowA.id);
      flowA.isUnique = isUnique;
    });

    // Mark flows in B as unique if not found in A
    flowsB.forEach((flowB) => {
      const isUnique = !flowsA.some((flowA) => flowA.id === flowB.id);
      flowB.isUnique = isUnique;
    });
  };

  const handleUpload = (file, whichFile) => {
    const reader = new FileReader();
    reader.onload = (fileEvent) => {
      const arrayBuffer = fileEvent.target.result;
      const zip = new JSZip();
      zip.loadAsync(arrayBuffer).then((contents) => {
        const entries = Object.entries(contents.files);

        // Continue handling files for ZIP B as per current logic
        if (whichFile === "B") {
          const requiredFiles = entries.filter(
            ([filePath]) =>
              filePath.startsWith("autoload.d/") ||
              filePath.startsWith("nodered/lib/flows/") ||
              filePath === "teldio-apps.json" ||
              filePath === "teldio-apps.zip"
          );

          // Store specific files and entries from ZIP B
          setZipContentsB(requiredFiles);
        } else {
          // Store all entries from ZIP A
          setZipContentsA(entries);
        }

        const networkFileEntry = entries.find(([filePath]) =>
          filePath.includes("network.json")
        );
        if (networkFileEntry) {
          const [, fileData] = networkFileEntry;
          fileData.async("string").then((networkJsonString) => {
            let network;
            try {
              network = JSON.parse(networkJsonString) || "none";
            } catch (error) {
              network = "none";
              console.log(error);
            }
            // Parse Node-RED flows and set them in the respective state based on whichFile
            if (whichFile === "A") {
              console.log("A", network);
              //setLeft(parseNodeRedFlows(flows, "old"));
              // Additionally, store the original flows JSON for ZIP A
              //setOriginalJsonOld(flows);
              setOriginalNetworkOld(network);
            } else {
              console.log("B", network);
              //setRight(parseNodeRedFlows(flows, "new"));
              // Store the original flows JSON for ZIP B
              //setOriginalJsonNew(flows);
              setOriginalNetworkNew(network);
            }
          });
        }

        const flowsFileEntry = entries.find(([filePath]) =>
          filePath.includes("nodered/flows.json")
        );
        if (flowsFileEntry) {
          const [, fileData] = flowsFileEntry;
          fileData.async("string").then((flowsJsonString) => {
            const flows = JSON.parse(flowsJsonString);
            // Parse Node-RED flows and set them in the respective state based on whichFile
            if (whichFile === "A") {
              setLeft(parseNodeRedFlows(flows, "old"));
              // Additionally, store the original flows JSON for ZIP A
              setOriginalJsonOld(flows);
            } else {
              setRight(parseNodeRedFlows(flows, "new"));
              // Store the original flows JSON for ZIP B
              setOriginalJsonNew(flows);
            }
          });
        }
      });
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="container">
      {zipContentsA.length > 0 && zipContentsB.length > 0 && (
        <div
          style={{
            backgroundColor: "white",
            width: "90%",
            marginTop: "10px",
            borderRadius: "30px",
            boxShadow: "15px 15px 19px rgba(0, 0, 0, 0.1)",
            padding: "20px 20px 40px 20px",
          }}
        >
          <Typography
            sx={{ textAlign: "center", marginBottom: "10px" }}
            variant="h4"
          >
            Networking
          </Typography>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={value}
                onChange={handleChangeRadio}
              >
                <FormControlLabel
                  value="old"
                  control={<Radio />}
                  label="Original"
                />
                <FormControlLabel value="new" control={<Radio />} label="New" />
              </RadioGroup>
            </FormControl>
          </div>

          <div
            style={{
              display: "flex",
              width: "90%",
              marginLeft: "5%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            {value === "old" && (
              <div
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography variant="h6" sx={{ marginTop: "10px" }} />
                  {originalNetworkOld?.eth0?.mode && (
                    <NetworkDisplay
                      data={originalNetworkOld.eth0}
                      name={"Ethernet 0"}
                      disabled={true}
                    />
                  )}
                  {originalNetworkOld?.eth1?.mode && (
                    <NetworkDisplay
                      data={originalNetworkOld.eth1}
                      name={"Ethernet 1"}
                      disabled={true}
                    />
                  )}
                </div>
                {originalNetworkOld?.eth0?.mode &&
                  originalNetworkOld?.eth1?.mode && (
                    <div style={{ width: "100%", marginTop: "20px" }}>
                      <TextField
                        fullWidth
                        label="DNS Nameservers"
                        disabled={true}
                        rows={5}
                        multiline
                        defaultValue={originalNetworkOld.nameservers}
                      />
                    </div>
                  )}
              </div>
            )}
            {value === "new" && (
              <div
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography variant="h6" sx={{ marginTop: "10px" }} />
                  {originalNetworkNew?.eth0?.mode && (
                    <NetworkDisplay
                      data={originalNetworkNew.eth0}
                      name={"Ethernet 0"}
                      adapter={"eth0"}
                      setOriginalNetworkNew={setOriginalNetworkNew}
                    />
                  )}
                  {originalNetworkNew?.eth1?.mode && (
                    <NetworkDisplay
                      data={originalNetworkNew.eth1}
                      name={"Ethernet 1"}
                      adapter={"eth1"}
                      setOriginalNetworkNew={setOriginalNetworkNew}
                    />
                  )}
                </div>
                {originalNetworkNew?.eth0?.mode &&
                  originalNetworkNew?.eth1?.mode && (
                    <div style={{ width: "100%", marginTop: "20px" }}>
                      <TextField
                        fullWidth
                        label="DNS Nameservers"
                        rows={5}
                        multiline
                        onChange={(e) =>
                          setOriginalNetworkNew((originalNetworkNew) => ({
                            ...originalNetworkNew,
                            nameservers: e.target.value,
                          }))
                        }
                        defaultValue={originalNetworkNew.nameservers}
                      />
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      )}
      {(zipContentsA.length === 0 || zipContentsB.length === 0) && (
        <div
          style={{
            backgroundColor: "white",
            width: "clamp(400px, 30%, 30%)",
            marginTop: "10px",
            borderRadius: "30px",
            boxShadow: "15px 15px 19px rgba(0, 0, 0, 0.1)",
            padding: "20px 20px 40px 20px",
          }}
        >
          <div className="itemContainer">
            <Typography variant="h4" sx={{ marginBottom: "40px" }}>
              Merge Files
            </Typography>
            <Typography variant="h5" sx={{ marginBottom: "20px" }}>
              Old TEG Configuration
            </Typography>
            <FileUploader handleChange={handleChangeA} name="file" />
            {zipContentsA.length > 0 ? (
              <Typography
                variant="subtitle1"
                sx={{ marginTop: "10px", textAlign: "center" }}
              >
                {zipNameA}
              </Typography>
            ) : null}
            <Typography
              variant="h5"
              sx={{ marginBottom: "20px", marginTop: "20px" }}
            >
              New TEG Configuration
            </Typography>
            <FileUploader handleChange={handleChangeB} name="fileB" />
            {zipContentsA.length > 0 ? (
              <Typography
                variant="subtitle1"
                sx={{ marginTop: "10px", textAlign: "center" }}
              >
                {zipNameB}
              </Typography>
            ) : null}
          </div>
        </div>
      )}

      {zipContentsA.length > 0 && zipContentsB.length > 0 && (
        <div
          style={{
            backgroundColor: "white",
            width: "90%",
            marginTop: "10px",
            borderRadius: "30px",
            boxShadow: "15px 15px 19px rgba(0, 0, 0, 0.1)",
            padding: "20px 20px 40px 20px",
          }}
        >
          <div>
            <Typography
              sx={{ textAlign: "center", marginBottom: "10px" }}
              variant="h4"
            >
              Nodered Flows
            </Typography>
            <Typography
              variant="h5"
              sx={{ textAlign: "center", marginTop: "30px" }}
            >
              Flows
            </Typography>
            <ScrollSync>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>{customList("Old Config Flows", left, "tab")}</Grid>

                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    {/* <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={() => {handleAllRight("tab")}}
                      disabled={left.length === 0}
                      aria-label="move all right"
                    >
                      ≫
                    </Button> */}
                    <IconButton
                      aria-label="right"
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                    >
                      <KeyboardArrowRightIcon />
                    </IconButton>
                    <IconButton
                      aria-label="left"
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                    >
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    {/* <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                    >
                      &gt;
                    </Button>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                    >
                      &lt;
                    </Button> */}
                    {/* <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleAllLeft}
                      disabled={right.length === 0}
                      aria-label="move all left"
                    >
                      ≪
                    </Button> */}
                  </Grid>
                </Grid>

                <Grid item>
                  {customList("New Config Flows", right, "tab")}{" "}
                </Grid>
              </Grid>
            </ScrollSync>
            <Typography
              variant="h5"
              sx={{ textAlign: "center", marginTop: "30px" }}
            >
              Subflows
            </Typography>
            <ScrollSync>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  {customList("Old Config Subflows", left, "subflow")}
                </Grid>

                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    {/* <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleAllRight}
                      disabled={left.length === 0}
                      aria-label="move all right"
                    >
                      ≫
                    </Button> */}
                    {/* <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                    >
                      &gt;
                    </Button> */}
                    <IconButton
                      aria-label="right"
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                    >
                      <KeyboardArrowRightIcon />
                    </IconButton>
                    <IconButton
                      aria-label="left"
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                    >
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    {/* <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                    >
                      &lt;
                    </Button> */}
                    {/* <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleAllLeft}
                      disabled={right.length === 0}
                      aria-label="move all left"
                    >
                      ≪
                    </Button> */}
                  </Grid>
                </Grid>

                <Grid item>
                  {customList("New Config Subflows", right, "subflow")}{" "}
                </Grid>
              </Grid>
            </ScrollSync>
          </div>
        </div>
      )}
      {zipContentsA.length > 0 && zipContentsB.length > 0 && (
        <div
          style={{
            marginTop: "20px",
            height: "auto",
            width: "100%",
            display: "flex",
            justifyContent: "right",
            marginRight: "30px",
            marginBottom: "30px",
          }}
        >
          <BigButton
            onClick={() => {
              createNewFile();
            }}
          >
            Download
          </BigButton>
        </div>
      )}
    </div>
  );
}

export default ZipUploader;
