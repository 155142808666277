import { Typography } from "@mui/material";
import "./App.css";
import ZipUploader from "./components/ZipUploader";

function App() {
  const params = new URLSearchParams(window.location.search);
  const showDiv = params.get("tools") !== "true";

  return (
    <div>
      {showDiv && (
        <div
          style={{
            width: "100%",
            height: "70px",
            backgroundColor: "#424242",
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            fontFamily: "fonts/Oxygen-Light",
            marginBottom: "20px",
          }}
        >
          <Typography
            sx={{
              width: "100%",
              color: "white",
              fontFamily: "fonts/Oxygen-Light",
              textAlign: "center",
            }}
            variant="h4"
          >
            Teldio Config Merger
          </Typography>
          <img
            src="/assets/logo.png"
            alt="teldio logo"
            style={{
              height: "35px",
              marginRight: "15px",
              cursor: "pointer",

              position: "absolute",
            }}
            onClick={() => {
              window.location.href = "/a/web/#/d/";
            }}
          />
        </div>
      )}
      <ZipUploader />
    </div>
  );
}

export default App;
