import { TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const NetworkDisplay = ({
  data,
  name,
  adapter,
  disabled,
  setOriginalNetworkNew,
}) => {
  const [type, setType] = useState(data?.mode || undefined);

  const handleUpdateValue = (value, field) => {
    setOriginalNetworkNew((originalNetworkNew) => ({
      ...originalNetworkNew,
      [adapter]: { ...originalNetworkNew[adapter], [field]: value },
    }));
  };
  const handleChange = (event) => {
    setType(event.target.value);
    setOriginalNetworkNew((originalNetworkNew) => ({
      ...originalNetworkNew,
      [adapter]: { ...originalNetworkNew[adapter], mode: event.target.value },
    }));
  };

  return (
    <div
      style={{
        display: "flex",
        width: "45%",
        justifyContent: "flex-start",
        height: "330px",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {type !== undefined ? (
        <>
          <Typography sx={{ textAlign: "left", width: "100%" }} variant="h5">
            {name}
          </Typography>
          <FormControl fullWidth disabled={disabled} sx={{ marginTop: "10px" }}>
            <InputLabel id="select-label">Mode</InputLabel>
            <Select
              labelId="select-mode"
              id="select"
              value={type}
              label="Mode"
              onChange={handleChange}
            >
              <MenuItem value={"dhcp"}>DHCP</MenuItem>
              <MenuItem value={"fixed"}>Fixed</MenuItem>
            </Select>
          </FormControl>
          {type === "fixed" && (
            <>
              <TextField
                fullWidth
                label="IP Address"
                disabled={disabled}
                defaultValue={data.ip}
                sx={{ marginTop: "10px" }}
                onChange={(e) => {
                  handleUpdateValue(e.target.value, "ip");
                }}
              />
              <TextField
                fullWidth
                label="Netmask"
                disabled={disabled}
                defaultValue={data.netmask}
                sx={{ marginTop: "10px" }}
                onChange={(e) => {
                  handleUpdateValue(e.target.value, "netmask");
                }}
              />
              <TextField
                fullWidth
                label="Gateway IP"
                disabled={disabled}
                defaultValue={data.gateway}
                sx={{ marginTop: "10px" }}
                onChange={(e) => {
                  handleUpdateValue(e.target.value, "gateway");
                }}
              />
            </>
          )}
        </>
      ) : (
        <Typography sx={{ textAlign: "left", width: "100%" }} variant="h5">
          No Networking Configuration Found
        </Typography>
      )}
    </div>
  );
};

export default NetworkDisplay;
